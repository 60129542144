<template>
    <div id="contact">
        <PageTitle :title="title" />
        <div class="contact-container">
            <div class="contact">
                <div class="photo">
                    <img src="/images/about/about.jpg" alt="Walters Plaxen">
                </div>
                <div class="details">
                    <div class="info">
                        <h2>SHAUNA WALTERS</h2>
                        <a class="info-item" href="tel:+310.775.1106">310.775.1106</a>
                        <a class="info-item" href="mailto:shauna@waltersplaxen.com">shauna@waltersplaxen.com</a>
                        <a class="info-item" target="_blank" href="https://www.instagram.com/shaunasellshomes/">@shaunasellshomes</a>
                        <span class="info-item">DRE#  01728600</span>
                    </div>
                    <div class="info">
                        <h2>Nicole Plaxen</h2>
                        <a class="info-item" href="tel:+310.710.7167">310.710.7167</a>
                        <a class="info-item" href="mailto:nicole@waltersplaxen.com">nicole@waltersplaxen.com</a>
                        <a class="info-item" target="_blank" href="https://www.instagram.com/nicole.deleo.plaxen/">@nicole.deleo.plaxen</a>
                        <span class="info-item">DRE#  01772151</span>
                    </div>
                    <hr class="divider">
                    <p class="address">
                        8878 W Sunset Blvd. West Hollywood, CA 90069
                    </p>
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="contact-form-container">
            <div class="contact-form">
                <h1>
                    LET'S CONNECT.
                </h1>
                <form method="post" @submit.prevent="handleSubmit">
                    <div class="form-items">
                        <div class="input-group">
                            <label for="FirstName">First Name*</label>
                            <input name="FirstName" v-model="data.FirstName" id="FirstName" type="text" required>
                        </div>
                        <div class="input-group">
                            <label for="LastName">Last Name*</label>
                            <input name="LastName" v-model="data.LastName" id="LastName" type="text" required>
                        </div>
                        <div class="input-group">
                            <label for="Email">Email Address*</label>
                            <input name="Email" v-model="data.Email" id="Email" type="Email" required>
                        </div>
                        <div class="input-group">
                            <label for="PhoneNumber">Phone Number*</label>
                            <input name="PhoneNumber" v-model="data.PhoneNumber" id="PhoneNumber" type="text" required>
                        </div>
                    </div>
                    <div class="form-message">
                        <label for="Message">Message*</label>
                        <textarea name="Message" v-model="data.Message" id="Message" cols="30" rows="10" required></textarea>
                    </div>
                    <div class="submit">
                        <button v-on:click="submitForm" class="btn-submit">
                            Submit <img src="/images/icon/arrow-white.svg" alt="Read More" />
                        </button>
                    </div>
                </form>
            </div>
        </div> -->
    </div>
</template>

<script>

import PageTitle from "../components/PageTitle"

export default {
    name: "Contact",
    components: {
        PageTitle
    },
    data(){
        return{
            title: "Contact",
            phone: "310.492.0737",
            address: {
                name: "Hilton & Hyland",
                street: "257 North Canon Drive",
                city: "Beverly Hills",
                zipcode: "CA 90210"
            },
            email: "info@.com",
            drenumber: "02032653",
            emailSubmitTo: 'info@waltersplaxen.com',
            data: {
                _subject: 'Contact Us - waltersplaxen.com',
                _cc:'Shauna@waltersplaxen.com,Nicole@waltersplaxen.com',
                Email: '',
                FirstName: '',
                LastName: '',
                PhoneNumber: '',
                Message: '',
            },
        }
    },
    metaInfo: {
        title: 'Contact',
        titleTemplate: '%s | WALTERS | PLAXEN',
        meta: [{
            name: 'description', 
            content: "WALTERS | PLAXEN"
        }]
    },
    methods:{
        clearForm(){
            this.data = {
                _subject: 'Contact Us - SWESTATES.com',
                _cc:'Shauna@waltersplaxen.com,Nicole@waltersplaxen.com',
                Email: '',
                FirstName: '',
                LastName: '',
                PhoneNumber: '',
                Message: '',
            }
        },
        submitForm(){
            this.axios({
                method: 'post',
                url: `https://formsubmit.co/ajax/${this.emailSubmitTo}`,
                data: this.data
            })
            .then(() =>{
                this.$toast.success("Success!", {
                    position: "top-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                })
                this.clearForm();
            })
            .catch(
                () => {
                    this.$toast.error("Error submitting, please try again.", {
                        position: "top-center",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                }
            )
        },
        handleSubmit(){
            console.log('submit')
        }
    },
}
</script>

<style lang="scss" scoped>
.contact-container{
    margin: 100px;
}
.contact{
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    .photo{
        width: 45%;
        img{
            width: 100%;
        }
    }
    .details{
        width: 55%;
        display: flex;
        flex-direction: column;
        h2{
            font-size: 40px;
            line-height: 42px;
        }
        p{
            font-size: 21px;
            line-height: 28px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #000000;
        }
    }
}
.info{
    margin-bottom: 40px;
    h2{
        text-transform: uppercase;
        margin: 30px 0;
    }
}
.info-item{
    display: block;
    font-weight: 300;
    text-decoration: none;
    text-transform: uppercase;
    font-family: poppins, sans-serif;
    color: #000;
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.01em;
}
.address{
    margin-top: 40px;
}
.divider{
    margin: 0;
    width: 80%;
}

.contact-form-container{
    margin: 100px;
}
.contact-form{
    max-width: 800px;
    margin: 0 auto;
}

.form-items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
}
.input-group{
    input{
        height: 40px;
        width: 100%;
    }
}
.form-message{
    grid-column: 2;
    margin-top: 40px;
    textarea{
        width: 100%;
        resize: none;
    }
}
.submit{
    text-align: center;
    margin-top: 40px;
}
@media (max-width: $tablet) {
    .contact-container{
        margin: 100px;
    }
    .contact{
        gap: 40px;
        flex-direction: column;
        .photo{
            width: 100%;
            img{
                width: 100%;
            }
        }
        .details{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

@media (max-width: $mobile) {
    .contact-container{
        margin: 20px;
    }
    .details h2{
        margin: 10px 0;
    }
    .info-item{
        font-size: 20.5px;
        line-height: 20.5px;
    }
    .contact-form-container{
        margin: 40px;
    }
    .contact-form{
        max-width: 800px;
        margin: 0 auto;
        h1{
            font-size: 50px;
        }
    }

    .form-items{
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;
    }
    .input-group{
        input{
            height: 40px;
            width: 100%;
            border: 1px solid #000;
        }
    }
    .divider{
        margin: 10px 10px;
        width: unset;
    }
    hr{
        margin: 10px 30px;
    }
    .form-message{
        grid-column: 2;
        margin-top: 40px;
        textarea{
            width: 100%;
            border: 1px solid #000;
            resize: none;
        }
    }
    .submit{
        text-align: center;
        margin-top: 40px;
    }
    .info{
        margin: 20px 0;
    }

}
</style>
